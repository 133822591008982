import React from "react";
import { graphql } from "gatsby";
import { connect as connectFela } from "react-fela";
import { GatsbyImage as Img } from "gatsby-plugin-image";

import Container from "components/container";
import IntroBlock from "components/IntroBlock";
import Layout from "components/layout";
import ReactPlayer from "react-player";
import fluidValues from "utilities/fluidValues";
import { getHexColor } from "theme/colors";

export default connectFela(({}) => ({
    container: {
        justifyContent: "center",
    },
    player: {
        position: "absolute",
        top: 0,
        left: 0,
    },
    wrapper: {
        position: "relative",
        paddingTop: "56.21%" /* Player ratio: 100 / (1280 / 720) */,
        ...fluidValues({
            marginTop: [60, 120],
            marginBottom: [60, 120],
        }),
        width: 1030,
    },
    imageWrapper: {
        width: "100vw",
        display: 'flex'
    },
    image: {
        width: '100%',
        maxHeight: 810
    },
}))(({
    path,
    pageContext,
    rules,
    styles,
    data: {
        jobs,
        settings,
        sanityVideoPage: { color, headerImage, meta, title, videoURL },
    },
}) => {
    return (
        <Layout
            pageColor={getHexColor(color, "100")}
            linkColor={getHexColor(color, "500")}
            title={title}
            slug={{ current: path }}
            meta={meta || {}}
            settings={settings}
            jobs={jobs}
            country={pageContext.country}
            pageContext={pageContext}
        >
            {headerImage && (
                <div className={styles.imageWrapper}>
                    <Img
                        style={{ position: rules.image?.position }}
                        credits={headerImage.credits}
                        className={styles.image}
                        image={headerImage.asset?.gatsbyImageData}
                        alt={""}
                    />
                </div>
            )}

            <Container
                id={"video"}
                flex
                withContentArea5
                tag={"section"}
                extend={{ styles: rules.container }}
            >
                <div className={styles.wrapper}>
                    <ReactPlayer
                        light={true}
                        url={videoURL}
                        className={styles.player}
                        width={"100%"}
                        height={"100%"}
                    />
                </div>
            </Container>
        </Layout>
    );
});

export const query = graphql`
    query ($country: String!) {
        settings: sanitySettings(countrySet: { eq: $country }) {
            ...Settings
        }

        jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
            edges {
                node {
                    ...Jobs
                }
            }
        }

        sanityVideoPage(countrySet: { eq: $country }) {
            title
            meta {
                title
                description
                other {
                    property
                    content
                }
            }
            slug {
                current
            }
            color
            videoURL
            headerImage {
                fitScreenWidth
                asset {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        width: 1440
                        placeholder: BLURRED
                    )
                }
            }
        }
    }
`;
